.title {
  margin-left: 0px;
  flex: 1;
}

.drawerContentContainer {
  width: 100vw;
}

@media (min-width: 600px) {
  .drawerContentContainer {
    width: 40vw;
  }
}
