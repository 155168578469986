.container {
  height: 100%;
  position: relative;
}

.map {
  height: 100%;
}

.fab {
  position: absolute;
  top: 10px;
  right: 10px;
}
