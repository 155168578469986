.changingPlacesLocator {
  flex: 1 1 0px;
  display: grid;
  width: 100%;
  grid-template-areas:
    "searchBar"
    "map"
    "list";
  grid-template-rows: auto 32vh minmax(0, 1fr);
  grid-template-columns: 1fr;
}

.searchBar {
  grid-area: searchBar;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.map {
  grid-area: map;
}

.list {
  grid-area: list;
  position: relative;
  overflow-y: auto;
}

.drawerContentContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.drawerContent {
  flex: 1 1 0;
  overflow-y: auto;
}

@media (min-width: 600px) {
  .changingPlacesLocator {
    flex: 1 1 0px;
    display: grid;
    width: 100%;
    grid-template-areas:
      "searchBar map"
      "list  map";
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 4fr 6fr;
  }

  .searchBar {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .drawerContentContainer {
    width: 40vw;
  }
}

.appBar {
  position: relative;
}

.title {
  margin-left: 16px;
  flex: 1;
}
