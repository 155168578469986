html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
