.fab {
  position: absolute;
  right: 32px;
  bottom: 32px;
  text-transform: none;
}

.formControl {
  margin: 8px;
  min-width: 120px;
  max-width: 300px;
}

.button {
  text-transform: none;
}
