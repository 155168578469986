.schedule {
  list-style: none;
  margin: 0;
  padding: 0;
}

.features {
  list-style: none;
  margin: 0;
  padding: 0;
}

.features .item {
  display: inline-block;
  width: 33%;
  padding-right: 3%;
}
