.root {
  flex: 0 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
}

.menuButton {
  margin-right: 16px;
}

.title {
  flex-grow: 1;
}

.button {
  border-radius: 40px;
  text-transform: none;
}

.language {
  margin: 0 4px 0 8px;
}
